<template>
<div>
	<CRow>
		<CCol sm="12" md="8">
        <CCard>
          <CCardHeader>
            <h4>Edit Fee Combination</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>
            <CForm>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Transaction Fee</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-input-group>
                        <b-form-select v-model="feeId" :options="optFees"></b-form-select>
                        <!-- <b-input-group-append>                        
                        <b-button variant="info" v-on:click="remFromList()">Select</b-button>
                        </b-input-group-append> -->
                    </b-input-group>
                   </b-col>                  
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Factor</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-input-group>
                        <b-form-select v-model="factorId" :options="optFactor" @change="factorChange" ></b-form-select>
                        <b-input-group-append>                        
                        <b-button variant="danger" v-on:click="remFromList()">Remove</b-button>
                        </b-input-group-append>
                    </b-input-group>
                   </b-col>                  
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >List Values</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-input-group>
                        <b-form-select v-model="values" :options="optValues"></b-form-select>
                        <b-input-group-append>                        
                        <b-button variant="info" v-on:click="combineFactor()">Add</b-button>
                        </b-input-group-append>
                    </b-input-group>
                   </b-col>                  
              </b-row><br/> 

              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Combinations</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <ul>
                        <div v-for="(value, key, index) in factors"> 
                            <li>{{ value }}</li>
                        </div>
                    </ul>
                  </b-col>                
              </b-row><br/>     

              
              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/cfactor" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="UpdateFactor()" variant="primary">Update</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>         
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import TrService from '@/api/TransactService.js';

  export default {    
    data() {
      return {  
          factorName:'', 
          value:'',        
          values:'',         
          optValues:[],
          id : this.$route.params.id,
          feeId:null,
          optFees:[],
          factorId:null,
          optFactor:[],
          baseFactor:[],
          factorName:[],         
          factors:[],
          seen:false,
          msg :'',
          color:''          
        };
    },
    filters: {        
        cleanstr(value){
           if(!value) return ''
           value = value.replace('{','')          
           return value         
        }
    },
    created () {   
       //var id = this.$route.params.id; 
       this.getTrFeeList();  
       this.getListSingleFactor(); 
       this.getFeeCombById(this.id);
    },
    methods: {
      
      factorChange(){        
         var factorId = this.factorId;
         var data = this.baseFactor;        
         const obj = JSON.parse(data[factorId]);
         this.optValues = obj;         
      }, 

      combineFactor(){
          let factor = {}; 
          var factorName = this.factorName[this.factorId];        
            
          if(this.values){ 
            var data = this.factors;
            for (var key in data) { var list = data[key];
                for (var key2 in list) {
                    if(factorName===key2){ this.factors.splice(key,1); }
                }
            }

            factor[factorName] = this.values;          
            this.factors.push(factor); this.values=''; 
          }       
          
          //console.log(this.factors);

      },

      addToList(){          
          var val = this.value; this.values = val;
          if(!this.optValues.includes(val)){
              this.optValues.push(val); 
          }          
      },

      remFromList(){
          var key = this.factorId;  
          var name = this.factorName[key];
          var factor = this.factors;

          for (var key in factor) {
              var row = factor[key];
              for (var key2 in row) {
                  if(key2===name){
                      this.factors.splice(key,1); 
                  }
              }
          }       
      },

      UpdateFactor(){ 
         
        let params = {}; var validate = [];        
        params['feeId'] = this.feeId; 
        
        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        var factors = this.factors; 
        var str = JSON.stringify(factors);
        var first = str.replace(/['{']/g,'');
        var second = first.replace(/['}']/g,'');
        var third  = second.replace(/['[']/g,'{');
        var fourth  = third.replace(/(\])/g,'}');
        
       params['combination'] = JSON.parse(fourth);   

        if(!validate.includes(false) && this.factors.length > 0 ){   //console.log(params); 
            if(localStorage.getItem('sa')==="true"){  
              this.UpdateCombFactor(this.id,params); 
            } else { 
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
            }            
        } else {
           this.msg   = 'Data Not Complete!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);

      }, 
      
      getFeeCombById: function(id) {
        TrService.getFeeCombById(id).then(resp => {   //console.log(resp);
            this.feeId = resp.transactionFeeId;
            var defcomb = JSON.parse(resp.factorsCombination); 
            for (var key in defcomb) {
               let factor = {};
               var value = defcomb[key];   factor[key] = value;
               this.factors.push(factor); 
            }            
        }, error => {
          this.spinner = false;
        });
      },      
      
      getTrFeeList: function() {
        TrService.getTrFeeList().then(resp => {  //console.log(resp); 
            var feeSrc=[];           
            for (const row of resp) {    
                 //if(row.recordStatus=== "ACTIVE"){                
                    feeSrc.push({ value: row.id , text: row.transactionType+" - "
                    + row.userGroupUniqueNumber+" - "+row.feeType+" - "+row.amount});
                // }
            }
            //console.log(feeSrc);
            feeSrc.push({ value: null  , text: '-- Select --' });
            this.optFees = feeSrc; 
        }, error => {
          this.spinner = false;
        });
      },

      getListSingleFactor: function() {
        TrService.getListSingleFactor().then(resp => {  //console.log(resp); 
            var facSrc=[];   let baseFactor={};  let factorName={};      
            for (const row of resp) {    
                facSrc.push({ value: row.id , text: row.factor });
                baseFactor[row.id] = row.factorValues;
                factorName[row.id] = row.factor;
            }
            facSrc.push({ value: null  , text: '-- Select --' });
            this.optFactor = facSrc; 
            this.baseFactor = baseFactor;
            this.factorName = factorName;

        }, error => {
          this.spinner = false;
        });
      },

      UpdateCombFactor: function(id,params) { 
          TrService.UpdateCombFactor(id,params).then(resp => {   //console.log(resp);           

            if(resp.message=="Success"){
                this.msg   = 'Update Factor Successfully'; 
                this.color = 'success';  this.seen  = true;
              } else {
                this.msg   = !resp.data.message ? "Update Factor Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>